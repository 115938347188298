import "./Logo.scss";

import React from "react";

export default _ => (
	<div className="logo">
		<span className="solar">Solar</span>
		<span className="food">Food</span>
	</div>
);
