import React from 'react'

export default () => (
  <>
    <h1>We make aquaponic systems for suburban backyards</h1>
    <p>
      "Aquaponics" is a fancy way of saying "plants floating in water, and fish
      in a tank, with a pump circulating water between them." It's extremely
      water- and space-efficient, taking up less than 5% of the water and space
      usage of traditional agricultural: an amazing reduction in environmental
      impact! What's more, everything it produces is fresh and organic; what's
      not to love?
    </p>
  </>
)
